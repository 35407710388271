<template>
  <v-container>
    <v-skeleton-loader type="table" v-if="!data_loaded"></v-skeleton-loader>
    <BaseTable
      ref="table"
      :headers="headers"
      :items="work_times"
      :status="false"
      :delete_meg="'حذف الوقت ؟ '"
      :delete_loader="delete_loader"
      :delete_dialog="delete_dialog"
      @delete_row="delete_time($event)"
      @edit_row="edit_row($event)"
      @close_delete="delete_dialog = false"
      @show_delete="delete_dialog = true"
      v-if="data_loaded"
    ></BaseTable>
    <!--table -->
    <Form
      v-if="data_loaded"
      :title="title"
      :form_obj="form_obj"
      ref="emForm"
      :form_error="alerts.error"
      :form_success="alerts.success"
      :success_text="alerts.success_text"
      :error_text="alerts.error_text"
      @save_form="save_form()"
      :save_loading="save_loading"
      @reset_id="(time_id = null), (title = 'اضافة وقت')"
      @close_snack="close_snack($event)"
    ></Form>
  </v-container>
  <!--/ container -->
</template>

<script>
import BaseTable from "@/components/base/table";
import Form from "@/components/work_times/form";

export default {
  name: "expenses",

  data() {
    return {
      delete_dialog: false,
      company_id: null,
      delete_loader: false,
      work_times: [],
      title: "اضافة وقت",
      data_loaded: false,
      save_loading: false,
      alerts: {
        success: false,
        error: false,
        success_text: "",
        error_text: "",
      },
      form_obj: {
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        start_time: null,
        end_time: null,
        employee_id: null,
        full_day: false,
      },
      headers: [
        {
          value: "date",
          text: "التاريخ",
          align: "center",
        },
        {
          value: "employee_name",
          text: "اسم الموظف",
          align: "center",
        },
        {
          value: "total_hours",
          text: "اجمالي الساعات",
          align: "center",
        },
        {
          value: "actions",
          text: "اجراءات",
          align: "center",
        },
      ],
    };
  },

  methods: {
    get_times() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "work_times",
            method: "get",
          },
        })
        .then((res) => {
          this.work_times = Object.assign([], res.data.data);
          this.data_loaded = true;
          console.log(res);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    save_form() {
      this.save_loading = true;
      this.$store
        .dispatch("public__request", {
          config: {
            url: this.company_id
              ? `work_times/${this.company_id}`
              : `work_times`,
            method: this.company_id ? "put" : "post",
          },
          data: this.form_obj,
        })
        .then((res) => {
          this.alerts.error = false;
          this.save_loading = false;
          this.alerts.success_text = "تم اضافة الوقت بنجاح";
          this.alerts.success = true;
          this.$refs.emForm.toggle_btn_form();
          this.get_times();
        });
    },
    close_snack(ev) {
      if (!ev.prop) {
        this.alerts[ev.type] = false;
      }
    },
    edit_row(ev) {
      this.title = "تعديل الوقت";
      Object.keys(ev.item).forEach((e) => {
        this.form_obj[e] = ev.item[e];
      });
      this.company_id = ev.item.id;
      this.$refs.emForm.fade_form();
    },
    delete_time(time_id) {
      this.delete_dialog = false;
      if (time_id) {
        this.delete_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: `work_times/${time_id}`,
              method: "delete",
            },
          })
          .then((res) => {
            this.alerts.success = true;
            this.alerts.success_text = "تم حذف الوقت";
            this.delete_loader = false;
            this.get_times();
            this.delete_dialog = false;
            if (this.$refs.emForm.toggle_form == true) {
              this.$refs.emForm.toggle_btn_form();
            }
          });
      }
    },
  },
  created() {
    this.get_times();
  },
  components: {
    BaseTable,
    Form,
  },
};
</script>
