<template>
  <div>
    <v-btn
      v-if="!toggle_form"
      @click="toggle_btn_form()"
      class="mt-10"
      fab
      small
    >
      <v-icon>add</v-icon>
    </v-btn>
    <v-form ref="form" class="mb-10">
      <v-card class="mt-10" v-if="toggle_form">
        <v-card-title>
          <span> {{ title }}</span>
          <v-spacer></v-spacer>
          <v-switch
            v-model="form_obj.full_day"
            :label="form_obj.fulle_day ? 'يوم كامل' : 'ساعات'"
            hide-details
          ></v-switch>
        </v-card-title>
        <v-divider></v-divider>
        <!--/.card title -->

        <v-card-text>
          <v-row class="mb-3">
            <v-col cols="12" sm="3">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="form_obj.date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form_obj.date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template slot="label">
                      <strong class="red--text">*</strong>
                      التاريخ
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker v-model="form_obj.date" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    الغاء
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(form_obj.date)"
                  >
                    تم
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="6" sm="3">
              <v-select
                :rules="[(v) => !!v || 'حقل مطلوب']"
                :items="employees"
                v-model="form_obj.employee_id"
                item-text="name"
                item-value="id"
                :loading="employees.length == 0"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  اختيار الموظف
                </template>
              </v-select>
            </v-col>
            <!--/ select employee -->

            <v-col cols="6" sm="3" v-if="!form_obj.full_day">
              <v-text-field
                :rules="rules.start_time"
                v-model="form_obj.start_time"
                @input="check_time({ value: $event, prop: 'start_time' })"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  وقت البداية
                </template>
              </v-text-field>
            </v-col>
            <!--/ start_time  -->
            <v-col cols="6" sm="3" v-if="!form_obj.full_day">
              <v-text-field
                :rules="rules.end_time"
                v-model="form_obj.end_time"
                @input="check_time({ value: $event, prop: 'end_time' })"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  وقت النهاية
                </template>
              </v-text-field>
            </v-col>
            <!--/ start_time  -->
          </v-row>
          <!--/ row -->

          <div class="text-end">
            <v-btn
              @click="
                $refs.form.reset(),
                  $refs.form.resetValidation(),
                  $emit('reset_id')
              "
              class="me-2"
              color="lightGray"
              outlined
            >
              <v-icon class="me-1">delete</v-icon>
              مسح
            </v-btn>
            <v-btn
              :disabled="save_loading"
              :loading="save_loading"
              @click="validate()"
              class="me-2"
              color="lightGray"
              outlined
            >
              <v-icon class="me-1" color="primary">save</v-icon>
              حفظ
            </v-btn>

            <v-btn
              @click="toggle_btn_form()"
              class="me-2"
              color="lightGray"
              outlined
            >
              رجوع
              <v-icon small class="me-1">arrow_back</v-icon>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
      <!--/ form -->
    </v-form>

    <v-snackbar
      @input="
        ($event) => $emit('close_snack', { props: $event, type: 'success' })
      "
      type="success"
      left
      :value="form_success"
    >
      <p>
        {{ success_text }}
      </p>
      <template v-slot:action>
        <v-icon color="success" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>

    <!--/ alert success -->

    <v-snackbar
      @input="
        ($event) => $emit('close_snack', { props: $event, type: 'error' })
      "
      color="error"
      left
      :value="form_error"
    >
      {{ error_text }}
      <template v-slot:action>
        <v-icon color="white" class="ms-3">error</v-icon>
      </template>
    </v-snackbar>

    <!--/ alert errors -->
  </div>
</template>

<script>
export default {
  name: "Form",
  props: [
    "title",
    "form_error",
    "form_success",
    "error_text",
    "success_text",
    "save_loading",
    "form_obj",
  ],
  data() {
    return {
      menu: false,
      employees: [],
      companies: [],
      toggle_form: false,
      rules: {
        start_time: [
          (v) => !!v || "حقل مطلوب",
          (v) =>
            /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || "الوقت غير صحيح",
        ],
        end_time: [
          (v) => !!v || "حقل مطلوب",
          (v) =>
            /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || "الوقت غير صحيح",
          (v) => !this.check_end_time || "يجب ان يكون اكبر من وقت البداية",
        ],
      },
    };
  },
  watch: {
    "form_obj.fulle_day"(ev) {
      if (!ev) {
        this.form_obj.start_time = null;
        this.form_obj.end_time = null;
      }
    },
  },
  computed: {
    check_end_time() {
      let start_time = new Date(
        `${this.form_obj.date} ${this.form_obj.start_time}:00`
      ).getHours();
      let end_time = new Date(
        `${this.form_obj.date} ${this.form_obj.end_time}:00`
      ).getHours();

      return end_time < start_time ? true : false;
    },
  },
  methods: {
    get_companies() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "companies",
            method: "get",
          },
        })
        .then((res) => {
          this.companies = Object.assign([], res.data.data);
          this.data_loaded = true;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    get_employees() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "employees",
            method: "get",
          },
        })
        .then((res) => {
          this.employees = Object.assign([], res.data.data);
          this.data_loaded = true;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    toggle_btn_form() {
      this.toggle_form = !this.toggle_form; // toggel form propery
      if (!this.toggle_form) {
        // if form is close back the employee index to default
        this.$emit("reset_id");
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
        this.form_obj.type = "employee";
        this.form_obj.payment_method = "cash";
        this.form_obj.date = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
      }
    },
    fade_form() {
      this.toggle_form = true;
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit("save_form");
      }
    },
    check_time(ev) {
      if (ev.value != null && ev.value.length == 2) {
        this.form_obj[ev.prop] = this.form_obj[ev.prop] + ":";
      }
    },
  },
  created() {
    this.get_companies();
    this.get_employees();
  },
};
</script>
